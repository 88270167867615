"use client"

import { Button } from "@/components/ui/button"
import { Card, CardHeader, CardContent } from "@/components/ui/card"
import { Flex } from "@/components/ui/flex"
import { Text } from "@/components/ui/text"
import { cn } from "@/lib/frontend/shadcn"

import { useAnalytics } from "@/lib/frontend/hooks/useAnalytics"
import { formatMoney } from "@/lib/util"
import { IconDiscountCheck, IconThumbDown, IconThumbDownFilled } from "@tabler/icons-react"
import React, { useState, useCallback } from "react"
import { JobCard } from "./JobCard"
import { getLogoUrl } from "@/lib/shared/gradientColors"
import { useStoreCardLayer } from "@/lib/frontend/hooks/statsig"
import { EmployerLogo } from "@/components/EmployerLogo"
import { uniqueJobCategories } from "@/lib/shared/categories"
import { Badge } from "./ui/badge"
import { FeedbackPrompt } from "./FeedbackPrompt"
import { StoreCardProps } from "./StoreCard"
import { useUserJobPreferences } from "@/lib/frontend/hooks/useUserJobPreferences"

export const INITIAL_NUM_OF_JOBS = 3

export const payRangeTextContent = (min: number, max: number) => {
  if (min === max) return `${formatMoney(min)} per hour`
  return `${formatMoney(min)} - ${formatMoney(max)} per hour`
}

export type StoreCardFeedbackProps = StoreCardProps & {
  isFeedbackPromptOpen: boolean
  onPromptOpen: () => void
  onPromptClose: () => void
}

export const StoreCardFeedback = ({
  store,
  selected,
  selectedJobId,
  onClick,
  position,
  onJobClick,
  isFeedbackPromptOpen,
  onPromptOpen,
  onPromptClose,
}: StoreCardFeedbackProps) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const jobs = expanded ? store.jobs : store.jobs.slice(0, INITIAL_NUM_OF_JOBS)
  const analytics = useAnalytics()
  const { storeCardAiBadges } = useStoreCardLayer()
  const logoUrl = getLogoUrl(store.employer.logo_url)
  const { recordNegativeEmployerFeedback, negativeFeedback } = useUserJobPreferences()

  // Randomly assign the "promoted" property to each job
  // jobs.forEach((job) => {
  //   job.promoted = Math.random() < 0.5
  // })

  // Sort the jobs array by the "promoted" property
  const jobsSortedByPromoted = jobs.slice().sort((a) => (a.promoted ? -1 : 1))
  // We map a default here, expand to support a customization at `Props` level
  const uiPlacement = "jobFeedStoreCard"
  const verifiedJob = jobsSortedByPromoted.some((job) => job.store_id === store.id && job.verified)
  const additionalJobs = store.jobs.length - INITIAL_NUM_OF_JOBS

  const handleCardClick = useCallback(() => {
    const trackingParams = {
      feedPosition: position,
      categories: store.employer?.categories?.join(", "),
      employer_title: store.employer?.title,
      verifiedJob: verifiedJob,
      ats_id: store.ats_id,
      pay_estimated: store.jobs.some((job) => job.pay_estimated),
      jobIsInDifferentBadgeTest: store.jobs.some((job) => job.urgent),
      logo: logoUrl,
    }
    analytics.track("StoreCard [click]", trackingParams)

    return onClick?.(store)
  }, [onClick, position, store, verifiedJob, analytics, logoUrl])

  const jobCategories = uniqueJobCategories(store)
  const [feedbackId, setFeedbackId] = useState<string | null>(null)

  return (
    <Card
      id={`store-card-${store.id}`}
      className={cn(
        "rounded-[20px]",
        "cursor-pointer",
        "border-[px]",
        "gap-2.5",
        selected ? "border-[#228620]" : "border-[#dee2e6]"
      )}
      onClick={handleCardClick}
    >
      <CardHeader className={cn("p-1.5")}>
        <Flex gap="sm" align="start">
          <Flex justify="start" align="center" wrap="wrap" gap="md">
            <EmployerLogo employerTitle={store.employer.title} employerLogoUrl={store.employer.logo_url} />

            {verifiedJob && (
              <IconDiscountCheck
                size={24}
                color="white"
                fill="#4dabf7"
                className={cn("ml-[-30px]", "mr-[-20px]", "mb-[-30px]", "z-20")}
              />
            )}
          </Flex>
          <div className="flex-1">
            <Text className={cn("max-xss:text-xs", "text-sm")} weight="semibold" lineClamp={1}>
              {store.employer.title}
            </Text>
            <Text className={cn("max-xss:text-xs", "text-sm")} weight="medium" color="muted" lineClamp={1}>
              {store.title}
            </Text>
          </div>
          <Flex align="center" asChild>
            <button disabled={isFeedbackPromptOpen || !!negativeFeedback[store.employer_id]}>
              {isFeedbackPromptOpen || !!negativeFeedback[store.employer_id] ? (
                <IconThumbDownFilled onClick={onPromptClose} />
              ) : (
                <IconThumbDown
                  onClick={async () => {
                    onPromptOpen()
                    const data = await recordNegativeEmployerFeedback.mutateAsync({
                      employer_id: store.employer_id,
                    })
                    setFeedbackId(data.id)
                  }}
                />
              )}
            </button>
          </Flex>
        </Flex>
      </CardHeader>
      {storeCardAiBadges() && (
        <Flex
          className={cn(
            "whitespace-nowrap gap-2 no-scrollbar overflow-x-scroll max-sm:pb-1",
            jobCategories.length === 0 && "hidden"
          )}
        >
          {jobCategories.map((jobCategory) => (
            <Badge
              key={jobCategory.value}
              size="xs"
              outlined
              className={cn("text-gray-800 border-gray-200 font-medium bg-muted")}
            >
              {jobCategory.label}
            </Badge>
          ))}
        </Flex>
      )}

      <FeedbackPrompt
        isFeedbackPromptOpen={isFeedbackPromptOpen}
        cancelPrompt={onPromptClose}
        feedbackId={feedbackId}
      />

      <CardContent>
        <Flex direction="col" className="gap-2.5">
          {jobsSortedByPromoted.map((job) => (
            <JobCard
              key={job.id}
              job={job}
              store={store}
              uiPlacement={uiPlacement}
              onJobClick={onJobClick}
              selected={job.id === selectedJobId}
            />
          ))}
          {store.jobs.length > 3 && !expanded && (
            <Button
              fullWidth
              variant="ghost"
              data-testid="load-more-button"
              className={cn("text-[#868e96] text-sm rounded-full hover:bg-[rgba(0.1,0.1,0.1,0.05)]")}
              onClick={(e) => {
                // If the user is focused on a job page, the store card is selected. Them choosing to expand the jobs
                // should keep the job page focused, which stopping propogation here does. If they choose to expand
                // a store card not associated with the current job page, it should close the job page.
                if (selected) {
                  e.stopPropagation()
                }

                setExpanded(true)
              }}
            >
              {`Show ${additionalJobs} more ${additionalJobs === 1 ? "job" : "jobs"}`}
            </Button>
          )}
        </Flex>
      </CardContent>
    </Card>
  )
}
