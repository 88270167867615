import { useEffect } from "react"
import { useParams } from "./ParamsProvider"
import { useToast } from "@/lib/frontend/hooks/use-toast"

const errorMap: Record<string, string> = {
  "email-job-not-found": "That job does not exist anymore. Here are some other jobs in the same area.",
  "google-login-failed": "Seems like we are having some problems logging in. Please try again.",
}
const errorsShown: Record<string, true> = {}

/**
 * Component that will show error notifications at the top of the page if
 * a valid error key is found in the URL. It will only show the error once per
 * page load.
 */
export const UrlErrorDisplay: React.FC = () => {
  const [searchParams, { deleteParams }] = useParams()
  const { toast } = useToast()
  const errorKey = searchParams?.get?.("error")

  useEffect(() => {
    if (errorKey && errorMap[errorKey] && !errorsShown[errorKey]) {
      toast({
        title: "Uh-oh!",
        description: errorMap[errorKey],
        variant: "destructive",
      })
      errorsShown[errorKey] = true
      setTimeout(() => deleteParams(["error"]), 100)
    }
  }, [errorKey, deleteParams, toast])

  return null
}
