import { useCallback } from "react"
import { ButtonAnchor } from "./ui/button"
import { usePortal } from "./PortalProvider"
import { ContactInfoCapture } from "@/components/ContactInfoCapture"
import { cn } from "@/lib/frontend/shadcn"
import { useAnalytics } from "@/lib/frontend/hooks/useAnalytics"
import { useWorkmapsContext } from "@/lib/frontend/hooks"
import { useUser } from "./UserContext"

type NotifyButtonProps = {
  buttonText: React.ReactNode
  className?: string
}

export const NotifyButton = ({ buttonText, className }: NotifyButtonProps) => {
  const emailModalPortal = usePortal("email-modal")
  const { state } = useWorkmapsContext()
  const analytics = useAnalytics()
  const user = useUser()
  const email = user?.email

  const handleCapture = useCallback(() => {
    const trackingParams = {
      jobCategories: state.jobCategories,
      minPay: state.payMin,
    }
    analytics.track("Notify Email Submitted", trackingParams)
    emailModalPortal.close()
  }, [analytics, state, emailModalPortal])

  const handleApplyEmailCapture = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault()

      if (!email) {
        emailModalPortal.open(
          <ContactInfoCapture
            onCapture={handleCapture}
            onClose={() => emailModalPortal.close()}
            title="🔔 Send Me Notifications!"
            description="Enter your email to receive notifications when new jobs are posted on Workmaps."
            cta="Send Me Notifications"
          />
        )
        analytics.track("Notify Email Button Clicked")
      } else {
        emailModalPortal.open(
          <ContactInfoCapture
            onCapture={handleCapture}
            onClose={() => emailModalPortal.close()}
            title="Email Already Submitted!"
            description="We will notify you when new jobs are posted!"
            showForm={false}
          />
        )
        analytics.track("Notify Email Button Clicked After Submission")
      }
    },
    [email, emailModalPortal, analytics, handleCapture]
  )

  return (
    <ButtonAnchor
      onClick={handleApplyEmailCapture}
      target="_blank"
      className={cn("p-3.5 bg-[#69AE59] hover:bg-[#69AE59]/90 text-white cursor-pointer", className)}
    >
      {buttonText}
    </ButtonAnchor>
  )
}
