import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "@/lib/frontend/shadcn"
import { Button } from "./button"
import { IconX, IconPlus } from "@tabler/icons-react"

const chipVariants = cva(
  "inline-flex items-center rounded-md border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      color: {
        primary: "bg-primary border-primary text-primary-foreground",
        secondary: "bg-secondary border-secondary text-secondary-foreground",
        muted: "bg-muted border-muted text-muted-foreground",
        success: "bg-success border-success text-success-foreground",
        destructive: "bg-destructive border-destructive text-destructive-foreground",
      },
      outlined: {
        true: "border border-solid bg-transparent",
      },
      size: {
        xss: "text-[11px] px-1 py-0",
        xs: "text-xs px-2 py-0.5",
        sm: "text-sm px-2.5 py-0.5",
        md: "text-base px-3 py-1",
        lg: "text-lg px-4 py-1.5",
      },
      radius: {
        none: "rounded-none",
        sm: "rounded-sm",
        md: "rounded-md",
        lg: "rounded-lg",
        full: "rounded-full",
      },
    },
    defaultVariants: {
      color: "secondary",
      outlined: true,
      size: "xs",
      radius: "full",
    },
  }
)

export type ChipProps = React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof chipVariants> & {
    add?: boolean
    remove?: boolean
    buttonClick: () => void
  }

const Chip = React.forwardRef<HTMLDivElement, ChipProps>(
  ({ className, color, outlined = false, size, radius, add, remove, buttonClick, ...props }, ref) => {
    const chipClasses = chipVariants({ color, outlined, size, radius })

    return (
      <div ref={ref} className={cn(chipClasses, "flex gap-1 items-center", className)} {...props}>
        <span className="">{props.children}</span>
        <Button onClick={buttonClick} variant="transparent" className="p-0 h-6">
          {remove && <IconX size={16} />}
          {add && <IconPlus size={16} />}
        </Button>
      </div>
    )
  }
)

Chip.displayName = "Chip"

export { Chip, chipVariants }
