"use client"

import { useAnalytics } from "@/lib/frontend/hooks/useAnalytics"
import { api } from "@/lib/api"
import { useCurrentUrl } from "@/lib/frontend/hooks"
import { useStatsigClient, useStoreFeedLayer } from "@/lib/frontend/hooks/statsig"
import { useIsMedium, debounce, replaceURL } from "@/lib/frontend/util"
import { getSearchParamsForMapState } from "@/lib/shared/util"
import { getLatLonBoxFromState } from "@/lib/util"
import type {
  ApiStoreSearchResult,
  ApiResponse,
  InitialLocationGeoData,
  MapState,
  WorkmapsState,
  SsrExposedExperiment,
} from "@/types"
import { Cluster } from "@googlemaps/markerclusterer"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import dynamic from "next/dynamic"
import { useSearchParams } from "next/navigation"
import { createContext, useCallback, useEffect, useReducer, useMemo } from "react"
import { useMap } from "./MapProvider"
import { cn } from "@/lib/frontend/shadcn"
import { UrlErrorDisplay } from "./UrlErrorDisplay"
import { MapListToggle } from "./MapListToggle"
import { StoresSidebar } from "@/components/StoresSidebar"
import { MobileStoreCardPreview } from "@/components/MobileStoreCardPreview"
import { SlugHeroDetailsProps } from "./SlugHeroDetails"
import { WhatWhereHeader } from "./WhatWhereHeader"
import { MobileWhatWhere } from "./MobileWhatWhere"
import { getLogoUrl } from "@/lib/shared/gradientColors"
import { Filters } from "./Filters"
import { useRouter } from "next/navigation"
import type { SlugPageResult } from "@/dal/getSlugPage"

const GoogleMapsMap = dynamic(() => import("./workmaps/GoogleMapsMap").then((mod) => mod.GoogleMapsMap), { ssr: false })
const GoogleMapsApiProvider = dynamic(
  () => import("./workmaps/GoogleMapsMap").then((mod) => mod.GoogleMapsApiProvider),
  { ssr: false }
)

export const SponsorContext = createContext<string | undefined>(undefined)

export type WorkmapsProps = Omit<MapState, "driftedFromDefaultState" | "onNextStoresUpdate"> & {
  userGeo: InitialLocationGeoData
  stores?: ApiStoreSearchResult[]
  storesPromise?: Promise<ApiStoreSearchResult[] | false>
  ssrExposedExperiments?: SsrExposedExperiment[]
  slug?: SlugPageResult
}

export const WorkmapsContext = createContext<WorkmapsState | undefined>(undefined)

export const Workmaps: React.FC<WorkmapsProps> = ({
  userGeo,
  slug,
  storesPromise,
  stores: initialStores = [],
  ssrExposedExperiments,
  ...initialFilters
}: WorkmapsProps) => {
  const analytics = useAnalytics()
  const queryClient = useQueryClient()
  const currentUrl = useCurrentUrl({ includeSearch: false })
  const searchParams = useSearchParams()!
  const isMedium = useIsMedium()
  const { map, mapVisible } = useMap()
  const { slugHeroDetail, storeCardClickOpensFirstJob, jobPageModal } = useStoreFeedLayer()
  const router = useRouter()
  const prefilledLocation =
    slug?.whereText ?? ("city" in userGeo && !initialFilters.storeId ? `${userGeo.city}, ${userGeo.region}` : undefined)

  // Mounting the map on mobile when it is not visible slows down scrolling through the list on low end Android phones.
  // Lazy loading it until they press on the map/list toggle makes the list snappy at the trade off of the map taking
  // a second to load when toggled.
  const mountMap = !isMedium || mapVisible

  const initialState: MapState = {
    ...initialFilters,
    lat: initialFilters.lat,
    lng: initialFilters.lng,
    driftedFromDefaultState: !initialStores || initialStores.length === 0,
    payMin: initialFilters.payMin ?? initialFilters.softMinPay,
  }

  const reducer = (state: MapState, newState: Partial<MapState>): MapState => {
    const derivedState: Partial<MapState> = {
      driftedFromDefaultState: state.driftedFromDefaultState,
    }

    // If the user landed on the site with a store ID and moves the map/filters, unselect it so the sidebar does not
    // scroll to it.
    if (state.storeId === initialFilters.storeId) {
      derivedState.storeId = undefined
    }

    // We only want to refresh the SSR map if the user moves it or applies a filter, not if they click on a job/store,
    // as that will refresh the list and possibly remove the target from the list.
    if (!derivedState.driftedFromDefaultState && !newState.job && !newState.storeId) {
      derivedState.driftedFromDefaultState = true
    }

    // If we trigger a job click, we need to set the storeId to the jobs's store, if it was not provided
    if (newState.job && !newState.storeId) {
      const stores = queryClient.getQueryData<ApiStoreSearchResult[]>(["stores"]) ?? []
      derivedState.storeId = stores.find((store) => store.jobs.find((job) => job.id === newState.job))?.id
    }

    return {
      ...state,
      ...derivedState,
      ...newState,
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  // #region Store storage
  // We store all of the stores (with jobs) in an empty query that defaults to what is passed by the server side.
  const { data: stores } = useQuery<ApiStoreSearchResult[]>({
    queryKey: ["stores"],
    initialData: initialStores,
  })

  const selectedStore = stores.find(
    (store) => store.id === state.storeId || (state.job && store.jobs.find((job) => job.id === state.job))
  )

  // If the server has sent a promise of the stores to load, resolve it and update the stores query with it.
  useQuery({
    queryKey: ["stores", "ssr"],
    refetchOnMount: true,
    enabled: !!storesPromise && !state.driftedFromDefaultState,
    queryFn: async () => {
      const stores = await storesPromise

      // If the stores preload times out, it will return false. In that case, we need to trigger loading the rest of the
      // stores client side. By setting the driftedFromDefaultState to true, it will immediately fire the stores query
      // below.
      if (!stores) {
        dispatch({ driftedFromDefaultState: true })
        return
      }

      queryClient.setQueryData(["stores"], stores)
    },
  })

  // This query will load stores as the user interacts with the map and filters. It is disabled until the user interacts
  // with the map/filters.
  const storesQuery = useQuery({
    queryKey: [
      "stores",
      {
        ...getLatLonBoxFromState({
          lat: state.lat,
          lng: state.lng,
          zoom: state.zoom,
          mapBounds: state.mapBounds!,
          map: map?.map,
          payMin: state.payMin,
          isMobile: isMedium,
          mapVisible,
        }),
        payMin: state.payMin,
        jobCategories: state.jobCategories,
        categories: state.categories,
        occupations: state.occupations,
        employers: state.employers,
        excludeEmployers: state.excludeEmployers,
        search: state.search,
        sortBy: state.sortBy,
        showHidden: state.showHidden,
        floor: state.floor,
        dynamicFloor: state.dynamicFloor,
        dynamicFloorLimit: state.dynamicFloorLimit,
        allJobs: state.allJobs,
        feeds: state.feeds,
      },
    ] as const,
    staleTime: 0,
    refetchOnWindowFocus: false,
    enabled: state.driftedFromDefaultState,
    queryFn: async ({ signal, queryKey: [, params] }) => {
      const updatedSearchParams = new URLSearchParams({
        lat0: params.lat0.toString(),
        lat1: params.lat1.toString(),
        lon0: params.lon0.toString(),
        lon1: params.lon1.toString(),
        limit: "300",
      })

      if (typeof params.showHidden === "boolean") updatedSearchParams.set("show_hidden", params.showHidden.toString())

      if (params.sortBy) updatedSearchParams.set("sort_by", params.sortBy)

      if (params.payMin) updatedSearchParams.set("soft_min_pay", params.payMin.toString())

      if (params.jobCategories?.length) updatedSearchParams.set("job_categories", params.jobCategories.join(","))

      if (params.categories?.length) updatedSearchParams.set("categories", params.categories.join(","))

      if (params.occupations?.length) updatedSearchParams.set("occupations", params.occupations.join(","))

      if (params.excludeEmployers?.length)
        updatedSearchParams.set("exclude_employers", params.excludeEmployers.join(","))

      if (params.employers?.length) updatedSearchParams.set("employers", params.employers.join(","))

      if (params.search?.length) updatedSearchParams.set("search", params.search.join(","))

      if (params.floor) updatedSearchParams.set("floor", params.floor.toString())

      if (typeof params.allJobs === "boolean") updatedSearchParams.set("all_jobs", params.allJobs.toString())

      if (params.feeds?.length) updatedSearchParams.set("feeds", params.feeds.join(","))

      if (params.dynamicFloor) updatedSearchParams.set("dynamic_floor", params.dynamicFloor.toString())

      if (params.dynamicFloorLimit && params.dynamicFloorLimit > 0)
        updatedSearchParams.set("dynamic_floor_limit", params.dynamicFloorLimit.toString())

      const response = await api.get("/api/stores", {
        signal,
        searchParams: updatedSearchParams,
      })

      if (!response.ok) throw new Error(response.statusText)

      const data = await response.json<ApiResponse<{ result: ApiStoreSearchResult[]; params: Record<string, any> }>>()

      if (!data.ok) throw new Error(data.error || "Unknown error")

      queryClient.setQueryData(["stores"], data.result)
      dispatch({ ...state, onNextStoresUpdate: undefined })
      state.onNextStoresUpdate?.({ ...state, stores: data.result })

      return data.result
    },
  })

  // #region Statsig
  // The user may have been exposed to experiments during server rendering. If they have, we need to log that exposure
  // on the frontend so that external systems are aware of it. Specifically, this allows us to see users in experiments
  // on Hotjar.
  //
  // Statsig logs exposures with the `.get` call.
  const statsig = useStatsigClient()
  useEffect(() => {
    if (ssrExposedExperiments) {
      ssrExposedExperiments.forEach((experiment) => {
        if (experiment.type === "layer") {
          const layer = statsig.getLayer(experiment.name)
          experiment.keys.forEach((key) => {
            layer.get(key)
          })
        } else if (experiment.type === "experiment") {
          const exp = statsig.getExperiment(experiment.name)
          experiment.keys.forEach((key) => {
            exp.get(key)
          })
        }
      })
    }
  }, [ssrExposedExperiments, statsig])

  // #region URL updating
  // As the user moves around the map, in most cases, we want to update the URL to match a subset of the MapState so it
  // can be loaded later.
  useEffect(() => {
    // On first load, we need to keep the URL parameters the same until the user moves the map, clicks a job, searches,
    // etc. If we do this immediately, Google thinks it is a redirect and will penalize us.
    //
    // We should update the URL if the user has selected a job or store though.
    if (!state.driftedFromDefaultState && !state.storeId && !state.job) {
      return
    }

    const oldSearchParams = new URLSearchParams(searchParams)
    const newSearchParams = getSearchParamsForMapState(
      searchParams,
      // On the mobile map view, users move the map a lot. So much so that we run into a hard limit on how often
      // `history.replaceState` can be called. To avoid this, we only store the lat/lng in the URL when the map is
      // moved. When the map is dismissed and the list view comes back, the URL will update to the new lat/lng. We also
      // want to update the URL if a store is selected so that sharing the job by URL works.
      mapVisible && isMedium && !state.storeId ? { ...state, lat: undefined, lng: undefined } : state
    )

    if (oldSearchParams.toString() !== newSearchParams.toString()) {
      replaceURL(`${currentUrl}?${newSearchParams.toString()}`)
    }
  }, [currentUrl, searchParams, state, mapVisible, isMedium])

  // #region Event handlers
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const handleMapChange = useCallback(
    debounce(({ lat, lng, zoom }: MapState) => {
      dispatch({
        lat,
        lng,
        zoom,
        driftedFromDefaultState: true,
      })
    }, 200),
    []
  )

  const handleStoreCardClicked = useCallback(
    (store: ApiStoreSearchResult) => {
      jobPageModal()
        ? router.push(`/job/${store.jobs[0].id}`)
        : dispatch({ storeId: store.id, job: storeCardClickOpensFirstJob() ? store.jobs[0].id : undefined })
    },
    [storeCardClickOpensFirstJob, jobPageModal, router]
  )

  const handleMapPinClicked = useCallback(
    (store: ApiStoreSearchResult) => {
      analytics.track("Pin click", {
        jobId: store.jobs[0].id,
        pay_estimated: store.jobs.some((job) => job.pay_estimated),
        logo: getLogoUrl(store.employer.logo_url),
      })

      // TODO Doing this with a JS event is dirty and we probably shouldn't do
      // it this way. We really need a UI reducer or some sort of event
      // listening system in React if we feel strongly about this.
      // When clicking on a pin, make the sidebar scroll to the store
      window.dispatchEvent(new CustomEvent("mapPinClicked", { detail: { storeId: store.id } }))
      dispatch({ storeId: store.id })
    },
    [analytics]
  )

  const handleClusterClick = useCallback(
    (_event: google.maps.MapMouseEvent, cluster: Cluster) => {
      analytics.track("Cluster click", { count: cluster.count })
    },
    [analytics]
  )

  const handleJobClick = useCallback(
    ({ jobId, storeId }: Record<"jobId" | "storeId", string>) => {
      jobPageModal() ? router.push(`/job/${jobId}`) : dispatch({ job: jobId, storeId: storeId })
      analytics.track("Job Clicked", { jobId, storeId })
    },
    [analytics, router, jobPageModal]
  )

  const handleJobPageClose = useCallback(() => {
    jobPageModal() ? router.back() : dispatch({ job: undefined, storeId: undefined })
  }, [jobPageModal, router])

  // Create the new slugHeroDetails (null or hydrate our content) (experiment: slug_hero_detail_v1)
  const slugHeroDetails = useMemo(() => {
    if (slug && typeof slug === "object" && slug.heroDetails.title && slug.heroDetails.content && slugHeroDetail()) {
      return {
        heroTitle: slug.heroDetails.title ?? "",
        heroContent: slug.heroDetails.content ?? "",
        imageUrl: slug.heroDetails.imageUrl ?? "",
        imageAltText: slug.heroDetails.imageAltText ?? "",
        imageWidth: slug.heroDetails.imageWidth ?? "",
        imageHeight: slug.heroDetails.imageHeight ?? "",
      }
    }
    return null
  }, [slug, slugHeroDetail])

  return (
    <WorkmapsContext.Provider value={{ state, dispatch }}>
      <div
        className={cn(
          "grid grid-rows-[min-content_auto] grid-cols-1 md:grid-cols-[220px_450px_1fr] w-full h-full overflow-hidden"
        )}
      >
        {!isMedium ? (
          <WhatWhereHeader
            initialState={initialState}
            initialLocation={prefilledLocation}
            isLoading={storesQuery.isLoading && stores.length === 0}
            className="grid col-span-full border-b"
          />
        ) : (
          <MobileWhatWhere initialState={initialState} initialLocation={prefilledLocation} />
        )}
        {mountMap && (
          <GoogleMapsApiProvider>
            <GoogleMapsMap
              stores={stores}
              initialState={initialState}
              onMapChange={handleMapChange}
              onStoreClick={handleMapPinClicked}
              selectedStore={selectedStore}
              onClusterClick={handleClusterClick}
              className={cn(mapVisible ? "max-sm:block" : "max-sm:hidden")}
            />
          </GoogleMapsApiProvider>
        )}
        <Filters className="max-md:hidden overflow-y-auto bg-white h-full pb-8 no-scrollbar z-30" />
        <StoresSidebar
          isLoadingStores={storesQuery.isLoading && stores.length === 0}
          stores={stores}
          selectedStoreId={state.storeId ?? selectedStore?.id}
          selectedJobId={state.job}
          onStoreClick={handleStoreCardClicked}
          slugHeroDetails={slugHeroDetails as SlugHeroDetailsProps | null}
          title={slug?.title ?? ""}
          className={cn(mapVisible && "max-md:hidden")}
          onJobClick={handleJobClick}
          onJobClose={handleJobPageClose}
          search={state.search}
          zoom={state.zoom}
        />
      </div>
      <MapListToggle className="md:hidden" onToggle={() => dispatch({ storeId: undefined })} />
      {selectedStore && mapVisible && isMedium && (
        <MobileStoreCardPreview
          store={selectedStore}
          selectedJobId={state.job}
          selectedJob={state.job && selectedStore ? selectedStore.jobs.find((job) => job.id === state.job) : undefined}
          onJobClick={handleJobClick}
          onJobClose={handleJobPageClose}
        />
      )}
      <UrlErrorDisplay />
    </WorkmapsContext.Provider>
  )
}
