import { SponsorContext, WorkmapsContext } from "@/components/Workmaps"
import { WorkmapsState } from "@/types"

import Cookies from "js-cookie"
import { usePathname, useSearchParams } from "next/navigation"
import { useCallback, useContext, useEffect, useState } from "react"
import { useDebouncedEffect } from "@react-hookz/web"

export type UseCurrentUrlParams = {
  includeSearch?: boolean
}

export const useCurrentUrl = ({ includeSearch = true }: UseCurrentUrlParams = { includeSearch: true }) => {
  const path = usePathname()!
  const searchParams = useSearchParams()!
  const url = new URL(path, process.env.NEXT_PUBLIC_SITE_URL)

  if (includeSearch) url.search = searchParams.toString()

  return url.toString()
}

export const useSponsor = () => {
  const sponsorContext = useContext(SponsorContext)
  return sponsorContext
}

export const useWorkmapsContext = () => {
  return useContext(WorkmapsContext) as WorkmapsState
}

const debugFlagNames = ["debug_feed_colors"] as const
type DebugFlagNames = (typeof debugFlagNames)[number]
type DebugFlags = Record<DebugFlagNames, boolean>

/**
 * Hook that returns conditional debug flags. These flags are set with boolean
 * values in the URL's search params and are persisted to cookies such that they
 * are retained on page refresh.
 */
export function useDebugFlags() {
  const searchParams = useSearchParams()!
  const flags: DebugFlags = debugFlagNames.reduce((acc, flagName) => {
    const flagValue = Cookies.get(flagName)
    if (flagValue) {
      acc[flagName] = !!JSON.parse(flagValue)
    }
    return acc
  }, {} as DebugFlags)

  const setFlag = useCallback((flagName: DebugFlagNames, value: boolean) => {
    Cookies.set(flagName, value.toString())
  }, [])

  useEffect(() => {
    debugFlagNames.forEach((flagName) => {
      if (searchParams.has(flagName)) {
        const value = searchParams.get(flagName)

        if (value) {
          setFlag(flagName, !!JSON.parse(value))
        } else if (value === "") {
          setFlag(flagName, true)
        }
      }
    })
  }, [searchParams, setFlag])

  return flags
}

export const useDebouncedValue = <T>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useDebouncedEffect(() => setDebouncedValue(value), [value], delay)

  return debouncedValue
}

// Copied in from @mantine/hooks
// https://github.com/mantinedev/mantine/blob/2f91229da10d30a205ade277161bc8829740bd93/packages/%40mantine/hooks/src/use-disclosure/use-disclosure.ts
export function useDisclosure(initialState = false, callbacks?: { onOpen?: () => void; onClose?: () => void }) {
  const { onOpen, onClose } = callbacks || {}
  const [opened, setOpened] = useState(initialState)

  const open = useCallback(() => {
    setOpened((isOpened) => {
      if (!isOpened) {
        onOpen?.()
        return true
      }
      return isOpened
    })
  }, [onOpen])

  const close = useCallback(() => {
    setOpened((isOpened) => {
      if (isOpened) {
        onClose?.()
        return false
      }
      return isOpened
    })
  }, [onClose])

  const toggle = useCallback(() => {
    opened ? close() : open()
  }, [close, open, opened])

  return [opened, { open, close, toggle }] as const
}
