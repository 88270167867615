import React from "react"
import { Text } from "@/components/ui/text"
import { Button } from "@/components/ui/button"
import { Flex } from "./ui/flex"
import { cn } from "@/lib/frontend/shadcn"
import { Card, CardContent } from "./ui/card"
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react"

export type SlugHeroDetailsProps = {
  heroTitle: string
  heroContent: string
  imageUrl: string
  imageAltText: string
  imageWidth: string
  imageHeight: string
  isExpanded: boolean
  componentExpansionToggle: (newState: boolean) => void
}

export const SlugHeroDetails: React.FC<SlugHeroDetailsProps> = ({
  heroTitle,
  heroContent,
  imageUrl,
  imageAltText,
  imageWidth,
  imageHeight,
  isExpanded,
  componentExpansionToggle,
}) => {
  const toggleExpand = () => {
    componentExpansionToggle(!isExpanded)
  }

  // Check if we have content to display
  const hasContent = heroTitle || heroContent || imageUrl

  if (!hasContent) {
    return null // Return null if there's no content to display
  }

  return (
    <Card className={cn("rounded-[20px]")}>
      <CardContent className="gap-3">
        <Flex align="center" justify="between" className="px-0.5">
          <Text weight="semibold" size="sm">
            {heroTitle}
          </Text>
          <Button variant="transparent" onClick={toggleExpand} className={cn("p-0 h-auto")}>
            <Flex className="gap-1">
              {isExpanded ? (
                <>
                  Collapse
                  <IconChevronUp size={20} />
                </>
              ) : (
                <>
                  Learn More
                  <IconChevronDown size={20} />
                </>
              )}
            </Flex>
          </Button>
        </Flex>
        {isExpanded && (
          <Flex className={cn("w-full gap-3 flex-shrink")}>
            <img
              src={imageUrl}
              width={imageWidth}
              height={imageHeight}
              alt={imageAltText}
              className={cn("rounded-[10px] object-cover max-h-[60px] max-w-[60px]")}
            />
            <Text className={cn("whitespace-pre-wrap text-sm line-clamp-3")}>{heroContent}</Text>
          </Flex>
        )}
      </CardContent>
    </Card>
  )
}
