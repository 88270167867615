import { Flex } from "./ui/flex"
import { Text } from "./ui/text"
import { Card, CardContent } from "./ui/card"
import {
  IconArrowRight,
  IconBuildingWarehouse,
  IconFirstAidKit,
  IconToolsKitchen,
  IconBuildingStore,
  IconBriefcase,
  TablerIconsProps,
} from "@tabler/icons-react"
import { cn } from "@/lib/frontend/shadcn"
import Link from "next/link"
import { useCurrentUrl } from "@/lib/frontend/hooks"
import { useAnalytics } from "@/lib/frontend/hooks/useAnalytics"
import { useStoreFeedLayer } from "@/lib/frontend/hooks/statsig"
import { useQuery } from "@tanstack/react-query"
import { api } from "@/lib/api"
import type { ApiResponse } from "@/types"

type NoMatchingJobsProps = {
  text: string
  className?: string
}
const iconMap: Record<string, (props: TablerIconsProps) => JSX.Element> = {
  "fast-food": IconToolsKitchen,
  nurse: IconFirstAidKit,
  "part-time": IconBriefcase,
  retail: IconBuildingStore,
  warehouse: IconBuildingWarehouse,
}

type SlugsResponseData = {
  result: {
    title: string
    slug: string
    icon: string
  }[]
}
export const NoMatchingJobs = ({ text, className }: NoMatchingJobsProps) => {
  const analytics = useAnalytics()
  const currentUrl = new URL(useCurrentUrl())
  const defaultIcon = "part-time"
  const { zeroResultsPanel } = useStoreFeedLayer()
  const { data: slugPages } = useQuery({
    queryKey: ["slugs"],
    queryFn: async ({ signal }) => {
      // if true (test-leg) and we use SlugsEndpoint to power panel
      if (zeroResultsPanel()) {
        const resp = await api.get(`/api/slugs`, { signal })
        const data = await resp.json<ApiResponse<SlugsResponseData>>()
        if (!data.ok) {
          throw data
        }

        return data.result.map((slug) => {
          return {
            ...slug,
            icon: iconMap[slug.icon] ?? defaultIcon,
          }
        })
      } else {
        // Control
        // return the original hard-coded 0 Result Matcher
        return [
          { title: "Fast Food Jobs", icon: IconToolsKitchen, slug: "fast-food-jobs-near-me" },
          { title: "Nursing Jobs", icon: IconFirstAidKit, slug: "nurse-jobs-near-me" },
          { title: "Part-Time", icon: IconBriefcase, slug: "part-time-jobs-near-me" },
          { title: "Retail Jobs", icon: IconBuildingStore, slug: "retail-jobs-near-me" },
          { title: "Warehouse Jobs", icon: IconBuildingWarehouse, slug: "warehouse-jobs-near-me" },
        ]
      }
    },
  })

  //console.log("Query state [no-matches]:", { slugPages, isLoading})
  if (!slugPages) return null

  const filteredSlugPages = slugPages.filter((slugPage) => !currentUrl.pathname.includes(slugPage.slug))
  const queryParameters = new URLSearchParams({
    lat: currentUrl.searchParams.get("lat") ?? "",
    lng: currentUrl.searchParams.get("lng") ?? "",
    zoom: "12",
  })

  return (
    <Flex direction="col" className={cn("px-3 gap-2", className)}>
      <Text size="sm" className="mb-2">
        {text}
      </Text>

      {filteredSlugPages.map((slugPage) => {
        const pathname = `/${slugPage.slug}`
        return (
          <Card
            key={slugPage.slug}
            className={cn("px-4 py-2.5 cursor-pointer hover:bg-[rgba(0.1,0.1,0.1,0.05)]")}
            onClick={() => analytics.track("No Match - Slug Page Clicked", { title: slugPage.title, pathname })}
            asChild
          >
            <Link href={`${pathname}?${queryParameters.toString()}`}>
              <CardContent justify="between" direction="row">
                <Flex gap="sm">
                  <slugPage.icon size={20} />
                  <Text size="sm">{slugPage.title}</Text>
                </Flex>
                <IconArrowRight size={20} />
              </CardContent>
            </Link>
          </Card>
        )
      })}
    </Flex>
  )
}
