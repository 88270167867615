"use client"

import { cn } from "@/lib/frontend/shadcn"
import { Text } from "@/components/ui/text"
import { Flex } from "@/components/ui/flex"
import { ButtonLink } from "@/components/ui/button"
import type { ApiStoreSearchResult, RequiredFunction } from "@/types"
import { defaultRangeExtractor, useVirtualizer } from "@tanstack/react-virtual"
import { useEffect, useRef, useCallback, useState } from "react"
import { NoOpErrorBoundary } from "@/components/ErrorBoundaries"
import { NoMatchingJobs } from "@/components/NoMatchingJobs"
import { StoresSidebarLoadingSkeleton } from "./StoresSidebarLoadingSkeleton"
import { useParams } from "@/components/ParamsProvider"
import { StoreCard } from "./StoreCard"
import { useStoreCardLayer, useStoreFeedLayer } from "@/lib/frontend/hooks/statsig"
import { JobPage } from "./JobPage"
import { useMap } from "./MapProvider"
import { useIsMedium } from "@/lib/frontend/util"
import { SlugHeroDetailsProps, SlugHeroDetails } from "./SlugHeroDetails"
import { hasAnyDisplayableJobCategories, uniqueJobCategories } from "@/lib/shared/categories"
import { StoreCardFeedback } from "./StoreCardFeedback"

import { useAnalytics } from "@/lib/frontend/hooks/useAnalytics"

export interface StoresSidebarV2Props {
  stores: ApiStoreSearchResult[]
  selectedStoreId?: string
  selectedJobId?: string
  title?: React.ReactNode
  isLoadingStores?: boolean
  slugHeroDetails?: SlugHeroDetailsProps | null
  onStoreClick?: (store: ApiStoreSearchResult) => void
  onJobClick?: ({ jobId, storeId }: Record<"jobId" | "storeId", string>) => void
  onJobClose?: () => void
  className?: string
  search?: string[]
  zoom?: number
}

export const StoresSidebar: React.FC<StoresSidebarV2Props> = ({
  stores,
  onStoreClick,
  selectedStoreId,
  selectedJobId,
  isLoadingStores,
  title,
  slugHeroDetails,
  onJobClick,
  onJobClose,
  className,
  search,
  zoom,
}) => {
  const jobsRef = useRef<HTMLDivElement>(null)
  const { storeBadge, storeCardAiBadges } = useStoreCardLayer()
  const { storeCardClickOpensFirstJob, storeCardFeedbackPrompt } = useStoreFeedLayer()
  const analytics = useAnalytics()

  // #region SEO and slug hero
  // Manage expand/collapse state for SlugHero in JobSideBar so Virtualizer can measure component height
  // Also necessry to keep the SlugHero collapse while scrolling otherwise virtualizer will we-open it
  const [slugHeroExpanded, setSlugHeroDetailExpansion] = useState(true)
  const toggleSlugHeroDetail = () => {
    setSlugHeroDetailExpansion((curr) => !curr)
  }

  // Build a Title for SlugPage when not using HeroDetails
  const [searchParams] = useParams()
  const utmTermValue = searchParams.get("utm_term")
  const nearMeLower = "near me"

  if (utmTermValue) {
    title = utmTermValue.toLowerCase().includes(nearMeLower) ? utmTermValue : `${utmTermValue} ${nearMeLower}`
  }

  // #region Job Page
  // On job card click, we want to animate the job card sliding out. We must decouple this animation from the query
  // parameters changing so it can finish.
  //
  // We set this to true initially so that hot linking to jobs works.
  const [slideOut, setSlideOut] = useState(true)

  const handleJobClick = useCallback<NonNullable<StoresSidebarV2Props["onJobClick"]>>(
    ({ jobId, storeId }) => {
      onJobClick?.({ jobId, storeId })
      setTimeout(() => setSlideOut(true), 50)
    },
    [onJobClick]
  )

  const handleJobClose = useCallback(() => {
    setSlideOut(false)

    if (onJobClose) {
      setTimeout(() => onJobClose(), 300)
    }
  }, [onJobClose])

  // Disabling job page animation on mobile map view because animation don't play well with it
  const { mapVisible } = useMap()
  const isMedium = useIsMedium()
  const disableAnimationOnMobile = mapVisible && isMedium
  let selectedStoreIndex = selectedStoreId ? stores.findIndex((store) => store.id === selectedStoreId) : undefined
  if (selectedStoreIndex === -1) selectedStoreIndex = undefined
  const selectedJob = selectedJobId
    ? stores.flatMap((store) => store.jobs).find((job) => job.id === selectedJobId)
    : undefined
  const selectedStore = selectedStoreId ? stores.find((store) => store.id === selectedStoreId) : undefined
  const showJobPage =
    selectedJob &&
    selectedStore &&
    (storeCardClickOpensFirstJob() || hasAnyDisplayableJobCategories(selectedJob.job_categories))

  const handleStoreClick: RequiredFunction<StoresSidebarV2Props["onStoreClick"]> = useCallback(
    (store) => {
      if (storeCardClickOpensFirstJob() && store.jobs.length) {
        handleJobClick({ jobId: store.jobs[0].id, storeId: store.id })
      }
      onStoreClick?.(store)
    },
    [onStoreClick, handleJobClick, storeCardClickOpensFirstJob]
  )

  const [openStoreCardPrompt, setOpenStoreCardPrompt] = useState<string | null>(null)

  const onPromptOpen = (id: string) => {
    setOpenStoreCardPrompt(id)
    analytics.track("Store Card Prompt Opened", { store_id: id })
  }

  const onPromptClose = () => {
    setOpenStoreCardPrompt(null)
    analytics.track("Store Card Prompt Cancelled")
  }

  // #region Virtualization and scrolling
  // Virtualization to make the list faster
  const [waitToClearJobAndStore, setWaitToClearJobAndStore] = useState(!!selectedStoreId)
  const virtualizer: ReturnType<typeof useVirtualizer<HTMLDivElement, Element>> | undefined = useVirtualizer({
    count: stores.length,
    overscan: 10,
    getScrollElement: () => jobsRef.current,
    paddingEnd: 10,
    rangeExtractor: useCallback<typeof defaultRangeExtractor>(
      (range) => {
        // When the store/job that is selected scrolls out of view, unselect the store and close the job page, if it's
        // open.
        if (
          !waitToClearJobAndStore &&
          typeof selectedStoreIndex !== undefined &&
          (range.startIndex > selectedStoreIndex! || selectedStoreIndex! > range.endIndex)
        ) {
          handleJobClose?.()
          setWaitToClearJobAndStore(false)
        }

        return defaultRangeExtractor(range)
      },
      [handleJobClose, selectedStoreIndex, waitToClearJobAndStore]
    ),
    estimateSize: useCallback(
      (idx) => {
        let size = 0
        // Handle SlugHeroDetails Component Height (idx=0) when enabled
        if (slugHeroDetails && idx === 0) {
          // If SlugHero Expanded
          if (slugHeroExpanded) {
            // Title +20
            // Padding + gap +32
            const heroContentLineCount = Math.ceil(slugHeroDetails.heroContent.length / 35)
            // Image size based on line height (2 lines = 40px, 3 lines = 60px), can't exceed 60px
            const cappedHeight = Math.min(heroContentLineCount * 20, 60)
            size += cappedHeight + 52
          } else {
            // Collapsed with just title
            size = 42
          }
        }
        // Store Card
        size = 100 // Height of store card without jobs

        // AI Store Badges
        if (uniqueJobCategories(stores[idx]) && storeCardAiBadges()) {
          size += 20
        }

        // Store Card Feedback Prompt expanded
        if (openStoreCardPrompt === stores[idx].id && storeCardFeedbackPrompt()) {
          // title question +20
          // checkbox with answers +24*4
          // text area +80
          // buttons + 36
          // padding + spacing +10*8
          size += 312
        }

        // TODO This doesn't account for store cards that are expanded and showing all
        // jobs. This should only be an issue for the initial load tho.
        for (const job of stores[idx].jobs.slice(0, 3)) {
          // The height of the job card with a single line of text
          size += 62

          // If any badges are applied, add 22px
          if (job.urgent && storeBadge() /* || isRecentlyAdded(job.job_posted_at) */) {
            size += 20
          }

          // Add 20px for each additional line of text. One line of text is roughly
          // 35 characters wide
          const lines = Math.ceil(job.title.length / 35)

          if (lines > 1) {
            size += 20 * (lines - 1)
          }
        }

        // The added size of the Load More jobs button
        if (stores[idx].jobs.length > 3) {
          size += 60
        }
        return size
      },
      [
        stores,
        slugHeroDetails,
        slugHeroExpanded,
        storeBadge,
        storeCardAiBadges,
        openStoreCardPrompt,
        storeCardFeedbackPrompt,
      ]
    ),
  })

  const items = virtualizer.getVirtualItems()

  // If the user is on a slug page and scrolls a bit, show them an alert that
  // they can reset filters to see more jobs.
  const [shownSeeMoreJobsAlert, setShownSeeMoreJobsAlert] = useState(false)
  const handleScroll = useCallback(() => {
    setShownSeeMoreJobsAlert((hasShown) => {
      if (hasShown) return true
      if (!jobsRef.current || window.location.pathname === "/" || window.location.pathname.startsWith("/all-jobs"))
        return false

      const scrollableHeight = jobsRef.current.scrollHeight
      const triggerHeight = 0.1 * scrollableHeight
      return jobsRef.current.scrollTop > triggerHeight
    })
  }, [jobsRef])

  const numOfJobs = stores.reduce((sum, store) => store.jobs.length + sum, 0)

  // When the app first loads, if we have a store id / job id in the URL, we need to scroll to the store after the store
  // list loads. This worked fine until we brought in the ability for stores to unselect / job pages to close as the
  // user scrolls the store out of view. The code below captures the initial store ID in a cached state that is unset
  // after the store loads and we scroll to it.
  //
  // TODO This is all avoidable if we were to order this store to the top of the job list in the API response / fetch it
  // during the initial render, as we do in the pins based solution.
  const [initialSelectedStoreId, setInitialSelectedStoreId] = useState(selectedStoreId)
  useEffect(() => {
    if (initialSelectedStoreId && selectedStoreIndex && virtualizer) {
      virtualizer.scrollToIndex(selectedStoreIndex, { align: "center" })
      waitForVirtualizerScroll(virtualizer).then(() => {
        setInitialSelectedStoreId(undefined)
        setWaitToClearJobAndStore(false)
      })
    }
  }, [virtualizer, initialSelectedStoreId, selectedStoreIndex])

  useEffect(() => {
    const scrollToStoreOnPinClick = async (event: CustomEvent<{ storeId: string }>) => {
      setWaitToClearJobAndStore(true)
      const storeIndex = stores.findIndex((store) => store.id === event.detail.storeId)

      if (storeIndex !== -1 && virtualizer) {
        virtualizer.scrollToIndex(storeIndex, { align: "center" })
        await waitForVirtualizerScroll(virtualizer).then(() => setWaitToClearJobAndStore(false))
      }
    }

    // @ts-expect-error Custom event listeners have bad types
    window.addEventListener("mapPinClicked", scrollToStoreOnPinClick)
    // @ts-expect-error Custom event listeners have bad types
    return () => window.removeEventListener("mapPinClicked", scrollToStoreOnPinClick)
  }, [stores, virtualizer])

  useEffect(() => {
    virtualizer.measure()
  }, [stores.length, virtualizer])

  // When the user searches or zooms in, we need to scroll to the top of the list
  const scrollToTopHash = search?.join(" ") + (zoom?.toString() ?? "")
  useEffect(() => virtualizer?.scrollToOffset(0), [scrollToTopHash, virtualizer])

  const heroComponent = slugHeroDetails && (
    <SlugHeroDetails
      {...slugHeroDetails}
      isExpanded={slugHeroExpanded}
      componentExpansionToggle={toggleSlugHeroDetail}
    />
  )

  if (isLoadingStores) {
    return (
      <StoresSidebarLoadingSkeleton
        hero={heroComponent}
        className={cn("row-start-2 md:col-start-2 row-end-2 md:col-end-2 col-span-full")}
      />
    )
  }

  return (
    <>
      {showJobPage && (
        <JobPage
          store={selectedStore}
          job={selectedJob}
          onClose={handleJobClose}
          className={cn(
            "row-start-2 col-start-1 row-end-2 md:row-start-2 overflow-y-auto z-[100] md:z-20 col-end-3 md:col-start-3 lg:w-[450px] w-auto",
            // Animation
            !disableAnimationOnMobile && [
              "transform transition-all ease-in-out duration-300 md:translate-y-0",
              slideOut ? "opacity-100" : "opacity-75",
              {
                // Mobile classes, slides up from the bottom
                "translate-y-0": slideOut,
                "translate-y-full": !slideOut,

                // Desktop classes, slides in from the left
                "md:translate-x-0": slideOut,
                "md:-translate-x-full": !slideOut,
              },
            ]
          )}
        />
      )}
      <Flex
        direction="col"
        className={cn(
          "bg-[#f8f9fa] h-full row-start-2 col-start-1 col-end- row-end-2 md:row-start-2 md:row-end-2 md:col-start-2 md:col-end-2 overflow-auto z-30",
          className
        )}
      >
        {title && !slugHeroDetails && (
          <Flex direction="row" justify="between" align="center" className={cn("px-2.5", title && "py-3")}>
            <Text as="h1" size="sm" weight="bold" transform="capitalize" lineClamp={1}>
              {title}
            </Text>
          </Flex>
        )}
        {!numOfJobs && !isLoadingStores && (
          <NoMatchingJobs
            text="No matching jobs found? Check out some of our curated job pages near you or consider removing some applied filters."
            className={cn(!title && "mt-3")}
          />
        )}
        <div className={cn("overflow-auto")} ref={jobsRef} onScroll={handleScroll}>
          <div className={cn("relative", "w-full")} style={{ height: virtualizer.getTotalSize() }}>
            <div
              className={cn("absolute", "top-0", "left-0", "w-full", !title && "pt-2.5")}
              style={{ transform: `translateY(${items[0]?.start ?? 0}px)` }}
            >
              {items.map((virtualRow) => {
                // Render SlugHeroDetails inside the virtualizer iterator otherwise the height breaks
                if (slugHeroDetails && virtualRow.index === 0) {
                  return (
                    <div className={cn("py-2.5 px-2.5")} key={virtualRow.key}>
                      {heroComponent}
                    </div>
                  )
                }
                const store = stores[virtualRow.index]
                return (
                  <div
                    className={cn("pb-2.5 px-2.5")}
                    key={virtualRow.key}
                    data-index={virtualRow.index}
                    ref={virtualizer.measureElement}
                  >
                    <NoOpErrorBoundary>
                      {storeCardFeedbackPrompt() ? (
                        <StoreCardFeedback
                          data-testid="StoreCard"
                          key={store.id}
                          store={store}
                          onClick={handleStoreClick}
                          selected={store.id === selectedStoreId}
                          selectedJobId={selectedJobId}
                          position={virtualRow.index + 1}
                          onJobClick={handleJobClick}
                          isFeedbackPromptOpen={openStoreCardPrompt === store.id}
                          onPromptOpen={() => onPromptOpen(store.id)}
                          onPromptClose={onPromptClose}
                        />
                      ) : (
                        <StoreCard
                          data-testid="StoreCard"
                          key={store.id}
                          store={store}
                          onClick={handleStoreClick}
                          selected={store.id === selectedStoreId}
                          selectedJobId={selectedJobId}
                          position={virtualRow.index + 1}
                          onJobClick={handleJobClick}
                        />
                      )}
                    </NoOpErrorBoundary>
                  </div>
                )
              })}
            </div>
          </div>
          {stores.length >= 1 && (
            <div className={cn("pb-20 sm:pb-5")}>
              <NoMatchingJobs text="Can't find what you're looking for? Check out some of our curated job pages near you." />
            </div>
          )}
        </div>
      </Flex>
      <ButtonLink
        replace
        className={cn(
          "fixed",
          "bottom-7",
          "right-7",
          "transition-opacity",
          !shownSeeMoreJobsAlert ? "opacity-0 invisible" : "opacity-100 visible"
        )}
        variant="darkPrimary"
        rounded="lg"
        href="/"
      >
        See more jobs &rarr;
      </ButtonLink>
    </>
  )
}

// Scrolling is not immediate and the virtualizer's scrollToIndex is not an awaitable promise. As such, we need
// to poll the isScrolling property to determine when the scroll is complete. If we do no do this, the
// virtualizer will clear the store selection in rangeExtractor.
async function waitForVirtualizerScroll(
  virtualizer: ReturnType<typeof useVirtualizer<HTMLDivElement, Element>> | undefined
) {
  if (!virtualizer) {
    return
  }

  await new Promise((resolve) => {
    const intervalId = setInterval(() => {
      if (!virtualizer?.isScrolling) {
        clearInterval(intervalId)
        resolve(true)
      }
    }, 100)
  })
}
